@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,900;1,100&display=swap');

/* .baseGradient {
    background: rgb(239, 162, 133);
    background: -moz-linear-gradient(65deg, rgba(239, 162, 133, 1) 0%, rgba(234, 178, 181, 1) 30%, rgba(220, 190, 221, 1) 74%, rgba(183, 175, 223, 1) 100%);
    background: -webkit-linear-gradient(65deg, rgba(239, 162, 133, 1) 0%, rgba(234, 178, 181, 1) 30%, rgba(220, 190, 221, 1) 74%, rgba(183, 175, 223, 1) 100%);
    background: linear-gradient(65deg, rgba(239, 162, 133, 1) 0%, rgba(234, 178, 181, 1) 30%, rgba(220, 190, 221, 1) 74%, rgba(183, 175, 223, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#efa285", endColorstr="#b7afdf", GradientType=1);
} */

.baseGradient {
    background-color: #2f2f3d;
}

body {
    margin: 0;
    padding: 0;
}

* {
    margin: 0;
    padding: 0;
}

.mainBack {
    width: 100vw;
}

.wordLogo {
    position: absolute;
    width: 20vw;
    right: 0rem;
    margin-top: 3rem;
    margin-right: 3rem;
}

.rectLogo {
    position: absolute;
    width: 20vw;
    top: 8vh;
    left: 10vw;
}

.mainContainer {
    width: 100vw;
    height: calc(100vw * 0.438);
}

.rulesAndRegulations {
    width: 100vw;
    /* height: 30rem; */
    /* background-color: rebeccapurple; */
    background: linear-gradient(to right, #355c7d, #6c5b7b, #c06c84);
    background-blend-mode: screen;
}

ul {
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    margin-left: 4rem;
}

li {
    margin-top: 0.2rem;
    margin-bottom: 0.2rem;
}

p {
    margin-left: 4rem;
    color: white;
    font-family: 'Roboto', sans-serif;
    margin-top: 0.2rem;
    margin-right: 2rem;
}

.themeDiv {
    display: flex;
    height: 100vh;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    background: #141E30;
    background: linear-gradient(to right, #0f2027, #203a43, #2c5364);
}

h1 {
    text-align: center;
    font-family: 'Roboto';
    font-style: italic;
    color: white;
    font-weight: 100;
    margin-top: 3rem;
    font-size: 4rem;
    margin: 2rem;
}

h2 {
    /* background: linear-gradient(to right, #417ceb, #2755d6, #0e349e);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
    text-align: center;
    color: white;
    font-family: 'Roboto';
    font-weight: 100;
    padding-top: 3rem;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.rulesAndRegulations {
    padding-bottom: 2rem;
}

.timerText {
    padding: 0;
    margin: 0;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    text-align: center;
    padding-top: 3rem;
    font-size: 3rem;
}

h3 {
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    font-size: 2rem;
    margin-top: 1rem;
    padding-bottom: 3rem;
}

.subContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.subContainer>img {
    width: 15rem;
    margin-top: 4rem;
    margin-bottom: 4rem;
    padding: 3rem;
    transition: all .2s ease-out;
}

.subContainer>img:hover {
    transform: translate(0, -10px);
}

@media (max-width: 1000px) {
    .rectLogo {
        top: 5vh;
    }
    ul {
        margin-right: 2rem;
    }
    .subContainer {
        flex-direction: column;
        align-items: center;
    }
    .subContainer>img {
        margin-top: 2rem;
    }
    .subContainer>img:hover {
        transform: translate(10px, 0);
    }
}

@media (min-width: 1300px) {
    ul {
        margin-left: 6rem;
    }
}


/* MAGAZINE STUFF */

.magHeader>img {
    width: 100vw;
}

.errorDiv {
    text-align: center;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 100;
    margin: 4rem;
    font-size: 2rem;
}