@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;900&display=swap');
body {
    background-color: #2f2f3d;
}

label input {
    display: block;
}

label {
    color: white;
    font-family: 'Roboto';
    font-weight: 100;
}

input {
    font-family: 'Roboto';
    font-weight: 100;
    margin-top: 0.2rem;
    margin-bottom: 1rem;
    border: 0;
    background-color: rgb(46, 46, 46);
    border-bottom: solid white 1px;
    color: white
}

input[type='file'] {
    border: 0;
}

form>button {
    border: 0;
    background: linear-gradient(to right, #1a2a6c, #b21f1f, #fdbb2d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: 'Roboto';
    font-weight: 100;
    font-size: 3rem;
    /* margin-top: 2rem; */
    padding: 2rem;
    transition: color .5s ease-out;
}

form>button:hover {
    background: none;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: initial;
    color: white;
}

.d_none {
    display: none;
}

.d_inline {
    display: inline;
}

.outerContainer {
    display: flex;
    justify-content: center;
}

.card {
    /* border: solid green 1px; */
    margin-top: 2rem;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    background-color: rgb(46, 46, 46);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.card>img {
    width: 10rem;
    margin-top: 2rem;
    margin-left: 4rem;
    margin-right: 4rem;
}

.card>form {
    max-width: 300px;
    margin: 4rem;
    margin-bottom: 2rem;
}

.loadingDiv {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

:root {
    --borderWidth: 7px;
    --height: 24px;
    --width: 12px;
    --borderColor: #78b13f;
}

.check {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: inline-block;
    transform: rotate(45deg);
    height: var(--height);
    width: var(--width);
    border-bottom: var(--borderWidth) solid var(--borderColor);
    border-right: var(--borderWidth) solid var(--borderColor);
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}